@<template>
  <div>
    <div
      v-if="
        this.$route.fullPath.includes('/quan-ly-customer') ||
        this.$route.fullPath == '/quan-ly-order'
      "
      class="container-body"
      style="margin: 20px auto"
    >
      <h3 class="gray">
        <span v-if="$route.params.path === 'quan-ly-customer'"
          >Tổng số lượng khách hàng
        </span>
        <span v-if="$route.params.path === 'quan-ly-order'"
          >Tổng số lượng đơn hàng
        </span>
        <span style="color: #ff8a00">({{ dataProps.length }})</span>
      </h3>

      <div v-if="$route.params.path === 'quan-ly-customer'" class="f gap1 mv05" style="flex-wrap: wrap;">
        <div class="mb1" style="width: 200px;"> 
          <label class="h7 mb1">Cấp độ khách hàng</label>
          <v-select 
            :options="dataLevels" 
            v-model="levelCustomer" 
            :reduce="level => level.id" 
            label="title_level_customer"
            placeholder="Chọn cấp độ"
            class="bg-white-1"
          />
        </div>
      </div>

      <div v-if="$route.params.path === 'quan-ly-order'" class="f aic gap1" style="flex-wrap: wrap;">
        Bộ lọc:
        <div class="f aic gap1">
          Trạng thái đơn hàng:
          <v-select 
            class="mt025 bg-white-1" 
            style="width: 200px;"
            :options="dataStatusOrder" 
            v-model="statusOrder" 
            :reduce="title_role => title_role.id" 
            label="title_status_order"
            placeholder="Chọn trạng thái đơn hàng"
          />
        </div>
        <div class="f gap1 mb1">
          <div class="f aic gap05">
            <input v-model="paid" type="radio" name="paid" id="check-paid" :value="2">
            <label for="check-paid">Đã thanh toán</label>
          </div>
          <div class="f aic gap05">
            <input v-model="paid" type="radio" name="paid" id="check-paid" :value="1">
            <label for="check-paid">Chưa thanh toán</label>
          </div>
  
          <div class="f aic gap05">
            <input v-model="cod" type="checkbox" name="paid" id="check-paid">
            <label for="check-paid">Đơn COD</label>
          </div>
  
          <div class="f aic gap05">
            <input v-model="isGift" type="checkbox" name="paid" id="check-paid">
            <label for="check-paid">Đơn quà tặng</label>
          </div>

          <div v-if="paid || statusOrder" class="ph1 pv05 border-radius cursor" style="border: 1px solid #6F382B;" @click="abortFilter">
            <p class="h7 brown" style="white-space: nowrap;">Huỷ lọc</p>
          </div>
        </div>
      </div>

      <router-link
        :to="{ name: 'body-detail', params: { id: item.id } }"
        v-for="(item, index) in dataFilter"
        :key="index"
      >
        <div class="card w100 mt1 border-radius bg-white-1">
          <div class="f jcb aic border-bottom pt1 pb1 pr1 pl1">
            <div v-if="$route.fullPath == '/quan-ly-customer'" class="f aic">
              <img
                :src="item.avatar"
                style="border-radius: 50%; width: 64px; height: 64px"
              />
              <div class="ml05">
                <h3 class="brown">{{ item.full_name }}</h3>
                <h5 class="gray">{{ item.email }}</h5>
              </div>
            </div>
            <div v-if="$route.fullPath == '/quan-ly-customer'" class="p4 blue">
              Xem chi tiết
            </div>
            <div v-if="$route.fullPath == '/quan-ly-order'" class="p5 red">
              {{ item.order_key }}
            </div>
            <div
              v-if="$route.fullPath == '/quan-ly-order'"
              class="status_order p5 red"
            >
              <div
                v-if="item.status_order.title_status_order === 'Chờ xác nhận'"
                class="pt025 pb025 pl05 pr05 border-radius orange bg-orange-1"
              >
                {{ item.status_order.title_status_order }}
              </div>
              <div
                v-if="item.status_order.title_status_order === 'Đang xử lý'"
                class="pt025 pb025 pl05 pr05 border-radius blue bg-blue-1"
              >
                {{ item.status_order.title_status_order }}
              </div>
              <div
                v-if="item.status_order.title_status_order === 'Thành công'"
                class="pt025 pb025 pl05 pr05 border-radius green bg-green-1"
              >
                {{ item.status_order.title_status_order }}
              </div>
              <div
                v-if="item.status_order.title_status_order === 'Đã huỷ'"
                class="pt025 pb025 pl05 pr05 border-radius red bg-red-1"
              >
                {{ item.status_order.title_status_order }}
              </div>
            </div>
          </div>

          <!-- customer -->
          <div
            v-if="$route.fullPath == '/quan-ly-customer'"
            class="f fdc mb1 pt1 pr1 pl1"
          >
            <div class="f jcb aic mb05">
              <h6 class="gray">Số điện thoại</h6>
              <div class="p4 black">{{ item.phone }}</div>
            </div>
            <div class="f jcb aic mb05">
              <h6 class="gray">Ghi chú</h6>
              <div class="p4 black">{{ item.note }}</div>
            </div>
            <div class="f jcb aic mb1">
              <h6 class="gray">Địa chỉ đặt hàng</h6>
              <div class="p4 black">{{ item.address }}</div>
            </div>
            <div class="f jcb aic mb1">
              <h6 class="gray">Phân loại khách hàng</h6>
              <div class="p4 black">{{ item.has_order ? 'Khách hàng thân thiết' : 'Khách hàng tiềm năng' }}</div>
            </div>
          </div>

          <!-- order -->
          <div
            v-if="$route.fullPath == '/quan-ly-order' && item.customer"
            class="f fdc mb1 pt1 pr1 pl1"
          >
            <div class="f jcb aic mb05">
              <h6 class="gray">Tên người mua</h6>
              <div class="p4 black">{{ item.customer["full_name"] }}</div>
            </div>
            <div class="f jcb aic mb05">
              <h6 class="gray">Số điện thoại</h6>
              <div class="p4 black">{{ item.customer["phone"] }}</div>
            </div>
            <div class="f jcb aic mb05">
              <h6 class="gray">Tổng sản phẩm</h6>
              <div class="p4 black">{{ item.total_quantity }}</div>
            </div>
            <div class="f jcb aic">
              <h6 class="gray">Tổng đơn hàng</h6>
              <div class="p4 brown">{{ formatMoney(item.total_price) }}</div>
            </div>
            <div v-if="item.paid" class="f jcb aic mt05">
              <h6 class="gray">Thanh toán</h6>
              <img src="@/assets/paid.png" style="width: 40px; height: auto; object-fit: cover; top: 0.5em; right: 0.5em; transform: rotate(45deg);" />
            </div>
          </div>
          <div
            v-if="$route.fullPath == '/quan-ly-order'"
            class="card-item__total f jcb aic pt1 pb1 pr1 pl1 bg-brown-1"
          >
            <div class="f fdc">
              <div class="h7 mb05">Tổng giá trị</div>
              <div class="p3 brown">{{ formatMoney(item.total_price) }}</div>
            </div>
            <div class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
              Xem chi tiết
            </div>
          </div>
        </div>
      </router-link>
    </div>
    <div v-else class="body w100 f jcc aic">
      <div class="body__content">
        <!-- <div class="content__select f aic mb2">
                    <div class="mr05" v-for="item in select" :key="item">
                        <div @click="handleFilter" class="select__item cursor pt05 pb05 pl075 pr075 h7 border-radius bg-brown-1 brown">
                            {{item.type}}
                        </div>
                    </div>
                </div> -->
        <div class="content__header f aic mb1">
          <div class="p3 gray mr025">
            Tổng số lượng {{ data.props.titlecontent }}
          </div>
          <div class="p3 red">({{ dataProps.length }})</div>
        </div>
        <div class="content__list">
          <CardView />
          <!-- :titleSelect="selectTitle" -->
        </div>
      </div>
    </div>

    <div class="hide">
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="more"
          d="M10.5 7.3125C10.5 7.14844 10.3359 6.98438 10.1719 6.98438H7.76562V4.57812C7.76562 4.41406 7.60156 4.25 7.4375 4.25H6.5625C6.37109 4.25 6.23438 4.41406 6.23438 4.57812V6.98438H3.82812C3.63672 6.98438 3.5 7.14844 3.5 7.3125V8.1875C3.5 8.37891 3.63672 8.51562 3.82812 8.51562H6.23438V10.9219C6.23438 11.1133 6.37109 11.25 6.5625 11.25H7.4375C7.60156 11.25 7.76562 11.1133 7.76562 10.9219V8.51562H10.1719C10.3359 8.51562 10.5 8.37891 10.5 8.1875V7.3125ZM13.7812 7.75C13.7812 4.00391 10.7461 0.96875 7 0.96875C3.25391 0.96875 0.21875 4.00391 0.21875 7.75C0.21875 11.4961 3.25391 14.5312 7 14.5312C10.7461 14.5312 13.7812 11.4961 13.7812 7.75ZM12.4688 7.75C12.4688 10.7852 10.0078 13.2188 7 13.2188C3.96484 13.2188 1.53125 10.7852 1.53125 7.75C1.53125 4.74219 3.96484 2.28125 7 2.28125C10.0078 2.28125 12.4688 4.74219 12.4688 7.75Z"
          fill="white"
        />
      </svg>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="search"
          d="M13.8906 13.5742C14.0547 13.7201 14.0547 13.875 13.8906 14.0391L13.2891 14.6406C13.125 14.8047 12.9701 14.8047 12.8242 14.6406L9.51562 11.332C9.44271 11.2591 9.40625 11.1862 9.40625 11.1133V10.7305C8.33073 11.6602 7.09115 12.125 5.6875 12.125C4.11979 12.125 2.77995 11.569 1.66797 10.457C0.55599 9.34505 0 8.00521 0 6.4375C0 4.86979 0.55599 3.52995 1.66797 2.41797C2.77995 1.30599 4.11979 0.75 5.6875 0.75C7.25521 0.75 8.59505 1.30599 9.70703 2.41797C10.819 3.52995 11.375 4.86979 11.375 6.4375C11.375 7.84115 10.9102 9.08073 9.98047 10.1562H10.3633C10.4544 10.1562 10.5273 10.1927 10.582 10.2656L13.8906 13.5742ZM2.59766 9.52734C3.45443 10.3841 4.48438 10.8125 5.6875 10.8125C6.89062 10.8125 7.92057 10.3841 8.77734 9.52734C9.63411 8.67057 10.0625 7.64062 10.0625 6.4375C10.0625 5.23438 9.63411 4.20443 8.77734 3.34766C7.92057 2.49089 6.89062 2.0625 5.6875 2.0625C4.48438 2.0625 3.45443 2.49089 2.59766 3.34766C1.74089 4.20443 1.3125 5.23438 1.3125 6.4375C1.3125 7.64062 1.74089 8.67057 2.59766 9.52734Z"
          fill="#6F382B"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import CardView from "@/components/CardView/CardView.vue";
import vSelect from 'vue-select'

import { HTTP } from "@/http-default";

export default {
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    dataProps: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    CardView,
    "v-select" : vSelect,
  },

  data() {
    return {
      paid: false,
      cod: false,
      isGift: false,
      dataStatusOrder: [],
      statusOrder: null,
      customerHasOrder: 1,
      dataLevels: [],
      levelCustomer: null,
    }
  },

  computed: {
    dataFilter() {
      let data = [...this.dataProps]
      if (this.$route.params.path === 'quan-ly-order') {
        if (this.paid)
          data = this.dataProps.filter(elm => elm.paid === (this.paid === 2))
        if (this.cod)
          data = this.dataProps.filter(elm => elm.cod)
        if (this.statusOrder)
          data = this.dataProps.filter(elm => elm?.status_order?.id === this.statusOrder)
        if (this.isGift)
          data = this.dataProps.filter(elm => elm?.is_gift)
        
      }

      if (this.$route.params.path === 'quan-ly-customer') {
        if (this.levelCustomer) {
          data = this.dataProps.filter(elm => elm.level_customer?.id === this.levelCustomer)
        }
      }
      return data
    }
  },

  created() {
    this.$route.params.path === 'quan-ly-order' && this.getStatusOrder()
    if (this.$route.params.path === 'quan-ly-customer') {
      this.getDataLevel()
    }
  },

  methods: {
    // formatMoney
    formatMoney(value) {
      var x = value;
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },

    async getStatusOrder() {
      await HTTP.get("v1/api/statusorder/").then((res) => {
        this.dataStatusOrder = res.data.results;
      });
    },

    abortFilter() {
      this.paid = null;
      this.cod = null;
      this.isGift = null;
    },

    getDataLevel() {
      HTTP.get("v1/api/levelcustomer/").then((res) => {
        this.dataLevels = res.data?.results || [];
        this.dataLevels = this.dataLevels.sort(
          (a, b) => (b?.level || 0) - (a?.level || 0)
        );
      });
    },
  },
};
</script>

<style scoped lang='scss'>
@import "ListLayout1.scss";
</style>
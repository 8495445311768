@<template>
  <div
    :class="
      isMobile
        ? 'create-post__customer--mobile'
        : 'create-post__customer f jcc ais'
    "
  >
    <div class="post-container relative">
      <div
        id="setting-post"
        class="f jce aic cursor"
        v-if="$route.params.id == dataInput.id"
      >
        <div
          @click="actionPost ? (actionPost = false) : (actionPost = true)"
          class="relative px075 f aic border-radius bg-white-1 mb1 z2"
        >
          <div v-if="actionPost" class="z2 icon--setting start"></div>
          <div v-if="!actionPost" class="z2 icon--setting reverse"></div>
          <div class="z2 p5 ml05">Hành động</div>

          <div
            v-if="actionPost"
            class="action-post__container border-radius absolute pl1 pr1 z1 shadow bg-white-1"
          >
            <div v-for="(item, index) in dataActionPost" :key="index">
              <div
                @mouseover="actionHover = item"
                @mouseleave="actionHover = null"
                @click="handlePutActionPost(item)"
                v-if="actionHover != null && actionHover.title === item.title"
                class="f aic pt1 pb1 border-bottom"
              >
                <div :class="'icon--' + item.icon"></div>
                <div class="p5 ml05 brown">{{ item.title }}</div>
              </div>
              <div
                @mouseover="actionHover = item"
                @mouseleave="actionHover = null"
                v-else
                class="f aic pt1 pb1 border-bottom"
              >
                <div :class="'icon--' + item.icon + '-1'"></div>
                <div class="p5 ml05 gray-1-text">{{ item.title }}</div>
              </div>
            </div>
            <div
              v-if="dataInput.approved_status === 'approved'"
              class="f aic pt1 pb1"
              @click="handlePinPostForum(dataInput.pin)"
            >
              <template v-if="dataInput.pin">
                <img src="@/assets/icons/unpin.svg" alt="" style="width: 12px" />
                <div class="p5 ml05 gray-1-text">Bỏ ghim</div>
              </template>
              <template v-else>
                <img src="@/assets/icons/pin.svg" alt="" style="width: 12px" />
                <div class="p5 ml05 gray-1-text">Ghim</div>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="create-post__form pt1 pb1 pl1 pr1 border-radius bg-white-1 relative">
        <!-- header -->
        <template v-if="dataInput.pin">
            <img src="@/assets/icons/pin.svg" alt="" class="absolute" style="width: 12px; height: auto; top: -0.5em; right: 0.25em; border: none;" />
        </template>
        <div class="form__header f jcb aic mb1">
          <div class="p5 brown">Thêm bài viết mới</div>
          <div
            v-if="$route.params.id == dataInput.id && dataInput.approved_status"
            class="p5 brown"
          >
            <!-- <div
                            v-if="dataInput.status_post.title_status_post === 'Đã đăng'"
                            class="px05 border-radius bg-green-1 green p4">
                            {{dataInput.status_post.title_status_post}}
                        </div>
                        <div
                            v-if="dataInput.status_post.title_status_post === 'Đã gỡ'"
                            class="px05 border-radius bg-orange-1 orange p4">
                            {{dataInput.status_post.title_status_post}}
                        </div>
                        <div
                            v-if="dataInput.status_post.title_status_post === 'Chưa duyệt'"
                            class="px05 border-radius bg-red-1 red p4">
                            {{dataInput.status_post.title_status_post}}
                        </div>
                        <div
                            v-if="dataInput.status_post.title_status_post === 'Lưu nháp'"
                            class="px05 border-radius bg-gray-2 gray p4">
                            {{dataInput.status_post.title_status_post}}
                        </div> -->
            <div
              v-if="dataInput.approved_status === 'draft'"
              class="f jcc aic pt05 pb05 px05 border-radius bg-gray-2"
            >
              <div class="p4 gray-1-text">Chưa duyệt</div>
            </div>
            <div
              v-if="dataInput.approved_status === 'canceled'"
              class="f jcc aic pt05 pb05 ph05 border-radius bg-red-1"
            >
              <div class="p4 gray-1-text">Đã huỷ</div>
            </div>
            <div
              v-if="dataInput.approved_status === 'approved'"
              class="f jcc aic pt05 pb05 px05 border-radius bg-green-1"
            >
              <div class="p4 gray-1-text">Đã duyệt</div>
            </div>
          </div>
        </div>

        <!-- body -->
        <div class="form__body">
          <div
            v-if="
              !($route.params.typePost == 1 || $route.path.includes('forums'))
            "
            class="f fdc ais mb1"
          >
            <label class="p4 mb025" for=""
              >Tiêu đề bài viết <span class="red">*</span></label
            >
            <input
              v-model="formInput.title_post"
              class="w100 h6 border-radius"
              type="text"
              placeholder="Nhập tên bài viết"
            />
          </div>
          <div class="f fdc ais mb1">
            <label class="f p4 mb025" for=""
              >Ảnh bìa bài viết <span class="red"> *</span>
              <div class="icon--info ml05"></div>
            </label>
            <div
              class="w100 f mt05 aic pt1 pb1 pl1 pr1 border-radius bg-gray-3"
              style="border: 1px dashed #d9dde8"
            >
              <div v-if="avatar.length !== 0" class="mr1">
                <img
                  v-if="$route.params.id == dataInput.id"
                  class="border-radius"
                  :src="avatar[0]"
                  alt=""
                  style="width: 65px; height: 65px; object-fit: cover;"
                />
                <img
                  v-else
                  class="border-radius"
                  :src="converImg(avatar[0])"
                  alt=""
                  style="width: 65px; height: 65px"
                />
              </div>
              <div
                v-if="avatar.length !== 0"
                @click="uploadImg"
                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor"
              >
                <input
                  type="file"
                  accept="image/*"
                  ref="file"
                  class="hide"
                  @change="uploadImage($event)"
                  id="file-input"
                />
                <i>Thay đổi ảnh bài</i>
              </div>
              <div
                v-if="avatar.length === 0"
                @click="uploadImg"
                class="h7 pt05 pb05 pr1 pl1 mr1 border-radius border cursor"
              >
                <input
                  type="file"
                  accept="image/*"
                  ref="file"
                  class="hide"
                  @change="uploadImage($event)"
                  id="file-input"
                />
                <i>Tải ảnh lên</i>
              </div>
              <div v-if="avatar.length === 0">
                <div class="p4">Thêm ảnh đại diện của bạn</div>
                <div class="h6 gray">Dung lượng ảnh tối đa: 2MB</div>
              </div>
            </div>
          </div>
          <div class="f fdc ais mb1">
            <label class="p4 mb025" for=""
              >Nội dung bài viết <span class="red">*</span></label
            >
            <textarea
              v-if="
                $route.params.typePost == 1 || $route.path.includes('forums') || (dataInput.system_show && dataInput.system_show[0].title_system === 'Forum')
              "
              v-model="formInput.content"
              rows="10"
              :resize="false"
              style="width: 100%; resize: none"
            >

            </textarea>
            <editor
              v-else
              api-key="4wl3mwjq0h6xnbsttg7khu6kcq0xy9vi5g6xsc52q90hglep"
              v-model="formInput.content"
              :init="{
                height: 500,
                width: '100%',
                menubar: false,
                placeholder: 'Mô tả sản phẩm',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                  'code',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help | link image tinydrive | code |',
                powerpaste_allow_local_images: true,
                powerpaste_word_import: 'prompt',
                powerpaste_html_import: 'prompt',
              }"
            />

            <!--<textarea 
                            v-model="formInput.content"
                            class="w100 h6 pt075 pb075 pl075 pr075 border-radius"
                            placeholder="Nhập tên bài viết"
                            name="" id="" cols="30" rows="5s"></textarea>-->
          </div>
          <div v-if="!isUserCreate" class="f fdc ais mb1">
            <label class="p4 mb025" for="">Bài viết dành cho hệ thống</label>
            <div
              class="w100 pt075 pb075 pl075 pr075 border-radius border bg-gray-2"
            >
              <div v-if="$route.params.id == dataInput.id" class="h6 gray">
                {{ dataInput.system_show[0].title_system }}
              </div>
              <div v-else class="h6 gray">
                {{ $route.params.typePost == 1 ? "Forum" : "Website" }}
              </div>
            </div>
          </div>
          <div
            v-if="
              !($route.params.typePost == 1 || $route.path.includes('forums'))
            "
            class="f fdc ais mb1"
          >
            <label class="p4 mb025" for="">Chuyên mục bài viết</label>
            <v-select
              :options="categorypost"
              placeholder="Chọn Chuyên mục bài viết"
              label="category_name"
              multiple
              v-model="formInput.category"
              :reduce="(category_name) => category_name.id"
            ></v-select>
          </div>
          <!-- <div v-if="!isUserCreate" class="f fdc ais mb1">
                        <label class="p4 mb025" for="">Bạn muốn</label>
                        <div class="f aic mb05">
                            <input 
                            v-model="formInput.status_post"
                            class="w100 h6 border-radius"
                            value="3"
                            type="radio" 
                            @click="saveDraft = true"
                            :checked='saveDraft'
                            name="imgsel"
                            placeholder="Nhập tên bài viết">
                            <div class="ml05 h6">Lưu nháp bài viết này</div>
                        </div>
                        <div class="f aic">
                            <input 
                                v-model="formInput.status_post"
                                class="w100 h6 border-radius"
                                value="1"
                                :checked='!saveDraft'
                                @click="saveDraft = false"
                                name="imgsel"
                                type="radio" 
                                placeholder="Nhập tên bài viết">
                            <div class="ml05 h6">Đăng bài viết này</div>
                        </div>
                    </div> -->
          <!-- <div v-if="!isUserCreate" class="f fdc ais mb1">
                        <label class="p4 mb025" for="">Trạng thái hiển thị bài viết <span class="red">*</span></label>
                        <v-select
                            :options="statusshowpost"
                            placeholder="Chọn trạng thái hiển thị"
                            label="title_status_show_post"
                            v-model="formInput.status_show_post"
                            :reduce="title_status_show_post => title_status_show_post.id"></v-select>
                    </div> -->

          <!-- Cài đặt nâng cao -->
          <div
            v-if="!advancedSettings && !isUserCreate"
            @click="advancedSettings = true"
            class="w100 f jcc aic pt05 pb05 border border-radius cursor mb1"
          >
            <div class="h7 blue">Cài đặt nâng cao</div>
          </div>
          <div
            v-if="advancedSettings"
            @click="advancedSettings = false"
            class="w100 f jcc aic pt05 pb05 border border-radius cursor mb1"
          >
            <div class="h7 blue">Tắt cài đặt nâng cao</div>
          </div>

          <div v-if="advancedSettings" class="advance__contaienr">
            <div class="f fdc ais mb1">
              <label class="p4 mb025" for="">Tiêu đề SEO</label>
              <input
                v-model="formInput.title_seo"
                class="w100 h6 border-radius"
                type="text"
                placeholder="Nhập tiêu đề SEO của bài viết"
              />
            </div>
            <div class="f fdc ais mb1">
              <label class="p4 mb025" for="">Mô tả SEO</label>
              <input
                v-model="formInput.description_seo"
                class="w100 h6 border-radius"
                type="text"
                placeholder="Nhập mô tả SEO của bài viết"
              />
            </div>
            <div class="f fdc ais mb1">
              <label class="p4 mb025" for="">Tag</label>
              <input
                v-model="formInput.tag"
                class="w100 h6 border-radius"
                type="text"
                placeholder="Nhập Tag của bài viết"
              />
            </div>
            <div class="f fdc ais mb1">
              <label class="p4 mb025" for="">Keyword</label>
              <input
                v-model="formInput.keyword"
                class="w100 h6 border-radius"
                type="text"
                placeholder="Nhập Keyword của bài viết"
              />
            </div>
          </div>
        </div>

        <!-- action btn -->
        <div class="form__action f jce aic">
          <!-- <div class="h5 pt075 pb075 pl1 pr1 border border-radius cursor">Quay lại</div> -->
          <div
            v-if="$route.params.id == dataInput.id"
            @click="handelUpdatePost"
            class="h5 pt075 pb075 pl1 pr1 ml1 border-radius bg-brown white cursor"
          >
            Lưu lại
          </div>
          <div
            v-else
            @click="handleAddPost"
            class="h5 pt075 pb075 pl1 pr1 ml1 border-radius bg-brown white cursor"
          >
            Đăng bài
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="$route.params.id == dataInput.id"
      class="post-infor px1 border-radius shadow bg-white-1"
      style="margin-top: 64px"
    >
      <div class="p5 brown">Thông số bài viết</div>
      <div class="f jcb aic pt1 pb1 border-bottom">
        <div class="p6">Lượt xem bài viết</div>
        <div class="p5 orange">{{ dataInput.total_click }} lượt</div>
      </div>
      <div class="f jcb aic pt1 pb1 border-bottom">
        <div class="p6">Lượt chia sẻ bài viết</div>
        <div class="p5 orange">{{ dataInput.total_share }} lượt</div>
      </div>
      <div v-if="dataInput.react" class="f jcb aic pt1 pb1 border-bottom">
        <div class="p6">Lượt tương tác</div>
        <div class="p5 orange">{{ dataInput.react.length }} lượt</div>
      </div>

      <div v-if="dataInput.react" class="f fdc ais pt1">
        <div class="w100 f jcb aic mb05">
          <div class="p6">Bình luận bài viết</div>
          <div class="p5 orange">{{ dataComment.length }} lượt</div>
        </div>
        <div @click="popupComment = true" class="p5 blue cursor">
          Xem tất cả bình luận
        </div>
      </div>
    </div>

    <ListComment
      v-if="popupComment"
      @closePopupComment="closePopupComment"
      @updateListCommnet="updateListCommnet"
      :dataComment="dataComment"
    />

    <NotiView
      v-if="notiView"
      @closePopup="closePopup"
      :title="titleNotiview"
      :logo="logo"
    />

    <Loading :is-loading="isLoadingCreate" type="page" />
  </div>
</template>

<script>
import NotiView from "@/components/NotiView/NotiView.vue";
import Loading from "@/components/Loading/Loading.vue";
import ListComment from "@/components/DetailItem/DetailPostAdmin/PopupListComment/ListComment.vue";

import { HTTP } from "@/http-default";
import vueSelect from "vue-select";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    editor: Editor,
    "v-select": vueSelect,
    NotiView,
    ListComment,
    Loading,
  },

  props: {
    dataInput: {
      type: Object,
      default() {
        return {};
      },
    },

    isUserCreate: {
      type: Boolean,
      default: false,
    },

    isMobile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      formInput: {},
      avatar: [],
      categorypost: [],
      statusshowpost: [],
      advancedSettings: false,
      saveDraft: true,

      popupComment: false,

      notiView: false,
      titleNotiview: "",
      logo: "",

      dataComment: [],

      actionPost: false,

      dataActionPost: [
        // {
        //     title : 'Đăng bài viết',
        //     id : 1,
        //     icon : 'post'
        // },
        // {
        //     title : 'Gỡ bài viết',
        //     id : 2,
        //     icon : 'remove-post'
        // },
        {
          title: "Duyệt bài viết",
          icon: "through-post",
          status_code: "approved",
        },
        {
          title: "Huỷ bài viết",
          icon: "remove-post",
          status_code: "canceled",
        },
        // {
        //     title : 'Xóa bài viết',
        //     id : 4,
        //     icon : 'delete-post'
        // }
      ],

      actionHover: null,
      isLoadingCreate: false,
    };
  },

  computed: {
    role () {
      return localStorage.getItem('role')
    }
  },

  watch: {
    dataInput: function () {
      this.formInput = { ...this.dataInput };
      this.avatar.push(this.dataInput.media[0].file_media);

      if (this.$route.params.id == this.dataInput.id) {
        console.log(123);
        HTTP.get("v1/api/commentpost/?depth=1&post=" + this.dataInput.id).then(
          (res) => {
            this.dataComment = res.data.results;
          }
        );
      }
    },
  },

  mounted() {
    this.formInput = { ...this.dataInput };
    this.formInput.category = this.$route.query?.category_post ? [Number(this.$route.query?.category_post)] : null
    if (
      this.$route.params.typePost == 1 ||
      this.$route.path.includes("forums")
    ) {
      this.formInput.title_post = "Bài viết Forum";
    }
  },

  methods: {
    uploadImg() {
      var input = document.getElementById("file-input");
      input.click();
    },

    closePopupComment() {
      this.popupComment = false;
    },

    closePopup() {
      this.notiView = false;
    },

    converImg(value) {
      return URL.createObjectURL(value);
    },

    updateListCommnet(value) {
      this.dataComment = value;
    },

    uploadImage(event) {
      this.avatar?.length
        ? (this.avatar[0] = event.target.files[0])
        : this.avatar.push(event.target.files[0]);
      // if (this.$route.params.path === 'quan-ly-customer' || this.$route.params.path === 'quan-ly-product') {
      //     this.dataInput.avatar.push(event.target.files[0])
      // }
    },

    makeKey() {
      var text = "";
      var possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < 20; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },

    handlePutActionPost(item) {
      if (item.id === 4) {
        HTTP.delete("v1/api/post/" + this.$route.params.id + "/").then(() => {
          this.$router.push({
            name: "body-view",
            params: { path: "quan-ly-post" },
          });
        });
      } else {
        // const data = JSON.stringify({
        //     "status_post" : item.id
        // })
        // // this.actionPost = false
        // HTTP.patch('v1/api/post/' + this.$route.params.id + '/', data)
        // .then(() => {
        //     location.reload()
        // })
        const data = JSON.stringify({
          new_status: item.status_code,
        });
        // this.actionPost = false
        HTTP.post("v1/api/post-status/" + this.$route.params.id, data).then(
          () => {
            location.reload();
          }
        );
      }
    },

    handelUpdatePost() {
      var data = { ...this.formInput };
      delete data.media;

      HTTP.patch("v1/api/post/" + this.$route.params.id + "/?depth=1", data);
    },

    handlePinPostForum(currentStatus) {
      HTTP.patch("v1/api/post/" + this.$route.params.id + "/?depth=1", {
        pin: !currentStatus,
      })
        .then(() => {
            this.notiView = true;
            this.titleNotiview = `${!currentStatus ? 'Ghim' : 'Bỏ ghim'} bài viết thành công`;
            this.logo = "noti-success";
        })
        .catch(() => {
            this.notiView = true;
            this.titleNotiview = `${!currentStatus ? 'Ghim' : 'Bỏ ghim'} bài viết thất bại`;
            this.logo = "noti-warning";
        });
    },

    async handleAddPost() {
      const idUser = JSON.parse(localStorage.getItem("idUser"));
      var media = new FormData();

      media.append("file_media", this.avatar[0]);
      media.append("user_created", idUser);
      media.append("title_media", Math.floor(Math.random() * 1000000000000));
      this.isLoadingCreate = true

      await HTTP.post("v1/api/mediapost/", media).then((res) => {
        var id = res.data.id;
        this.formInput.media = [];
        this.formInput.system_show = [];
        this.formInput.media.push(id);
        this.formInput.user_created = idUser;
        this.formInput.slug = this.makeKey();
        this.formInput.permalink = "link";
        if (this.role === 'ADMIN') this.formInput.approved_status = 'approved'

        if (
          this.$route.params.typePost == 1 ||
          this.$route.path.includes("forums")
        ) {
          // 1: Forum, 2: Website
          this.formInput.system_show.push(1);
        } else {
          this.formInput.system_show.push(2);
        }

        for (let item in this.formInput) {
          if (this.formInput[item] === "" || this.formInput[item]?.length === 0 || !this.formInput[item]) {
            delete this.formInput[item];
          }
        }

        var data = JSON.stringify(this.formInput);

        HTTP.post("v1/api/post/", data)
          .then((res) => {
            if (!this.isUserCreate) {
              this.notiView = true;
              this.titleNotiview = "Tạo bài viết thành công";
              this.logo = "noti-success";
            }
            this.isLoadingCreate = false
            if(this.$route.params.path === "quan-ly-post") {
              this.$router.go(-1)
            } else this.$emit("createSuccess", { isError: false, data: res.data.data });
          })
          .catch(() => {
            if (!this.isUserCreate) {
              this.notiView = true;
              this.titleNotiview = "Tạo bài viết thất bại";
              this.logo = "noti-warning";
            }
            this.isLoadingCreate = false
            this.$emit("createSuccess", { isError: true });
          });
      });
    },

    getData() {
      HTTP.get("v1/api/categorypost/").then((res) => {
        this.categorypost = res.data.results;
      });

      HTTP.get("v1/api/statusshowpost/").then((res) => {
        this.statusshowpost = res.data.results;
      });
    },
  },

  created() {
    this.getData();
  },
};
</script>

<style scoped lang='scss'>
@import "AddNewPost.scss";
</style>
<template>
  <div class="transport-account__container container">
    <div class="container-header f aic mb2">
      <div class="qcont h2 pr1 border-right">Vận chuyển</div>
      <div class="h5 pr05 pl1 brown">Cài đặt tài khoản vận chuyển</div>
    </div>

    <div>
      <div>
        <div class="transport-partner__list f fdc gap1">
          <div
            v-for="(item, index) in listTransportPartner"
            :key="index"
            class="transport-partner__list__item px05 border-radius f gap1 cursor bg-white-1 w100"
          >
            <img :src="item.image" alt="" class="border-radius" />
            <div class="f fdc gap05">
              <h5 class="h5 pv05">{{ item.title }}</h5>
              <div v-if="item.dataAccount" class="h7">
                Thông tin tài khoản: <span v-if="item.dataAccount" class="brown h7">{{ item.dataAccount.phone }}</span>
                <div class="green h7 cursor" @click="item.isChangeAccount = true">
                  Đổi tài khoản
                </div>
              </div>

              <form v-if="!item.dataAccount || item.isChangeAccount">
                <div class="f gap1" style="flex-wrap: wrap;">
                  <div class="f fdc">
                    <label class="h7 mb05" for="">Tên đăng nhập</label>
                    <input
                      class="h6"
                      v-model="item.dataConnect.user_name"
                      type="text"
                      placeholder="Nhập tên đăng nhập"
                    />
                  </div>
                  <div class="f fdc">
                    <label class="h7 mb05" for="">Mật khẩu</label>
                    <input
                      class="h6"
                      v-model="item.dataConnect.password"
                      type="password"
                      placeholder="Nhập mật khẩu"
                      autocomplete="new-password"
                    />
                  </div>
                  <div class="f fdc jce">
                    <div class="h7 pt075 pb075 ph1 align-c border-radius bg-brown white cursor" style="height: fit-content;" @click="connectTransportAccount(item)">Đăng nhập</div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NotiView v-if="notiView" @closePopup="notiView = false" :title="title" />

    <Loading :isLoading="isLoading" :type="'page'" />
  </div>
</template>

<script>
import Loading from "@/components/Loading/Loading.vue";
import NotiView from "@/components/NotiView/NotiView.vue";

import { HTTP } from "../../http-default";
export default {
  name: "TransportAccountPage",

  components: {
    Loading,
    NotiView,
  },

  data() {
    return {
      isLoading: false,
      notiView: false,
      logo: "noti-success",
      title: "",

      dataAccount: [],

      // vận chuyển
      listTransportPartner: [
        {
          code: "VTP",
          image: require("@/assets/dlo/viettel_post.png"),
          title: "Viettel Post",
          description: "Đi sâu hơn, đi xa hơn để con người gần nhau hơn",
          dataAccount: null,
          dataConnect: {
            user_name: "",
            password: "",
            company_code: "DRMOM",
            transport_partner_code: "VTP",
          },
          isChangeAccount: false,
        },
        // {
        //   code: 'JT',
        //   image: require('~/assets/img/dlo/jt.png'),
        //   title: 'J&T Express',
        //   description: 'Express your online business',
        // },
        {
          code: "GHTK",
          image: require("@/assets/dlo/ghtk.png"),
          title: "Giao hàng tiết kiệm",
          description: "Giao hàng nhanh và rẻ, tiết kiệm là hàng đầu",
          dataAccount: null,
          dataConnect: {
            user_name: "",
            password: "",
            company_code: "DRMOM",
            transport_partner_code: "GHTK",
          },
          isChangeAccount: false,
        },
      ],
    };
  },

  created() {
    this.getDataTransportAccount();
  },

  methods: {
    async getDataTransportAccount() {
      const res = await HTTP.get("v1/api/transportaccount/?depth=1");
      const data = res?.data?.results || []
      const dataVTP = data.find(e => e.transport_partner?.code === 'VTP')
      const dataGHTK = data.find(e => e.transport_partner?.code === 'GHTK')
      this.listTransportPartner[0].dataAccount = dataVTP?.account_data
      this.listTransportPartner[1].dataAccount = dataGHTK?.account_data
    },

    async connectTransportAccount(partner) {
      const payload = {
        ...partner.dataConnect,
      };

      this.isLoading = true;
      try {
        const res = await HTTP.post(
          "/order/connect-transport-account/",
          payload
        );
        const data = res.data
        this.isLoading = false;
        this.logo = "noti-success";
        this.title = data?.message || "Kết nối thành công";
        this.getDataTransportAccount()
      } catch (e) {
        console.error(e);
        this.isLoading = false;
        this.notiView = true;
        this.logo = "noti-error";
        this.title = "Kết nối thất bại. Vui lòng thử lại sau!";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.transport-partner {
  &__list {
    &__item {
      border: 2px solid #fff;

      & img {
        width: 120px;
        height: 120px;
        aspect-ratio: 1;
        object-fit: cover;
      }

      &--active {
        color: #6f382b;
        font-weight: 600;
        border: 2px solid #6f382b;
      }
    }
  }
}
</style>
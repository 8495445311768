@<template>
  <div v-if="!isMobile()" class="header__container f jcb aic bg-white-1 z3">
    <div class="container--forums-header w100 f jcb aic">
      <div class="f aic">
        <div class="logo__drmom">
          <img src="../imgLanding/logo.png" alt="" />
        </div>
        <div class="nav__container f aic ml1">
          <div class="cursor" v-for="(item, index) in dataNav" :key="index">
            <template v-if="item.listChildren">
              <div class="dropdown" tabindex="0">
                <input type="checkbox" id="dropdown" />
                <label class="dropdown__face" for="dropdown">
                  <div class="dropdown__text h4 gray-1-text">
                    {{ item.title }}
                  </div>
                  <div class="dropdown__arrow"></div>
                </label>

                <ul class="dropdown__items">
                  <SubMenu
                    v-if="item.listChildren && item.listChildren.length > 0"
                    :path="item.path"
                    :dataMenu="item.listChildren"
                  />
                </ul>
              </div>
            </template>
            <router-link
              v-else
              :to="{ name: 'body-view', params: { path: item.path } }"
              class="relative"
            >
              <div
                class="h4 brown item-menu-title"
                v-if="$route.params.website === item.path"
              >
                {{ item.title }}
              </div>
              <div v-else class="h4 gray-1-text item-menu-title">
                {{ item.title }}
              </div>

              <div v-if="item.listChildren" class="item-menu absolute bg-white">
                <p
                  v-for="(child, indexChild) in item.listChildren"
                  :key="indexChild"
                  class="sub-item-menu"
                >
                  {{ child.category_name }}
                </p>
              </div>
            </router-link>
          </div>
          <router-link
            :to="{ name: 'body-view', params: { path: 'product' } }"
            v-if="account"
          >
            <div v-if="$route.params.website === 'product'" class="h4 brown">
              Shop
            </div>
            <div v-else class="h4 gray-1-text">Shop</div>
          </router-link>

          <router-link
            :to="{ name: 'body-view', params: { path: 'gift' } }"
            v-if="account"
          >
            <div v-if="$route.params.website === 'gift'" class="h4 brown">
              Quà tặng
            </div>
            <div v-else class="h4 gray-1-text">Quà tặng</div>
          </router-link>
        </div>
      </div>
      <div
        v-if="!account"
        class="action__container f aic pt075 pb075 pl1 pr1 border-radius bg-brown"
      >
        <div
          @click="(LoginPopup = true), (statusFormProp = 'register')"
          class="h5 pr05 border-right white cursor"
        >
          Đăng ký
        </div>
        <div
          @click="(LoginPopup = true), (statusFormProp = 'login')"
          class="h5 pl05 white cursor"
        >
          Đăng nhập
        </div>
      </div>

      <div
        v-if="account"
        @click="handleLogin"
        class="action__container relative f aic border-radius"
      >
        <div v-if="myAccount && myAccount.full_name !== ''" class="p4 brown">
          {{ myAccount.full_name }}
        </div>
        <div v-if="myAccount && myAccount.full_name === ''" class="p4 brown">
          {{ myAccount.user__username }}
        </div>
        <div class="ml1 mr1">
          <img class="image_user" :src="myAccount && myAccount.avatar" alt="" />
        </div>
        <img src="../../../assets/Header/Down.png" alt="" />

        <PopupLogout></PopupLogout>
      </div>

      <LoginView
        v-if="LoginPopup"
        @closePopup="closePopup"
        :statusFormProp="statusFormProp"
      />
    </div>
  </div>

  <div v-else class="w100 header__container-mobile f jcb aic">
    <div class="logo__drmom" @click="$router.push('/website')">
      <img src="../imgLanding/logo.png" alt="" />
    </div>

    <div class="f aic gap1">
      <!-- Giỏ hàng -->
      <div
        class="shopping-cart__icon relative cursor"
      >
        <div
          v-if="!listCartcontainer"
          @click="listCartcontainer = true"
          class="icon icon--cart"
        ></div>

        <div
          v-if="listCartcontainer"
          @click="listCartcontainer = false"
          class="icon icon--cart"
        ></div>

        <div
          v-if="dataProductCart && dataProductCart.length"
          class="count-cart_container f jcc aic absolute bg-brown"
        >
          <div class="p7 white">{{ dataProductCart.length }}</div>
        </div>
        <!-- list cart item -->
        <div
          v-if="listCartcontainer"
          class="shopping-cart__list absolute border-radius bg-white-1"
        >
          <div class="cart-list__header f jcb aic pt1 pl1 pr1">
            <div class="h4 brown">Giỏ hàng sản phẩm</div>
          </div>

          <!-- có sản phẩm trong giỏ hàng -->
          <div
            v-if="dataProductCart && dataProductCart.length"
            class="cart-list__body f fdc p1 relative border-radius"
          >
            <div
              v-for="(item, index) in dataProductCart"
              :key="index"
              class="product__cart"
              :style="{ 'animation-deley': index * 0.4 + 's' }"
              :id="'product-' + item.id"
            >
              <div
                class="f aic pt05 pb05 pl05 pr05 border-radius bg-gray-2"
                style="gap: 0.5em"
              >
                <div class="cart-product__img f jcc">
                  <img
                    class="border-radius border"
                    :src="item.media[0].file_media"
                    alt=""
                  />
                </div>
                <div class="cart-product__title w100">
                  <div class="mb05 f jcb aic">
                    <div
                      style="max-width: calc(100% - 30px)"
                      class="two-line p5"
                    >
                      {{ item.title_product }}
                    </div>
                    <div
                      style="width: 10px"
                      @click="handleDelete(item)"
                      class="icon--close mr05 cursor"
                    ></div>
                  </div>
                  <div class="f jcb aic w100">
                    <div>
                      <div class="h6 mb025 gray-1-text text-line-through">
                        {{ converMoney(item.price_product) }}
                      </div>
                      <div class="p5 orange">
                        {{ converMoney(item.price_sale) }}
                      </div>
                    </div>
                    <input
                      v-model="item.quantity"
                      class="align-c border border-radius bg-white-1"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Không có sản phẩm trong kho -->
          <div v-if="dataProductCart && dataProductCart.length === 0" class="f fdc jcc aic p1">
            <div class="icon--face-smile mb1"></div>
            <div class="p5">Bạn chưa có sản phẩm trong giỏ hàng</div>
          </div>

          <div
            v-if="dataProductCart && dataProductCart.length !== 0"
            class="cart-list__action p1 bg-brown-1"
          >
            <div class="f jcb aic mb1">
              <div class="p4">Tổng tiền</div>
              <div class="h3 brown">{{ converMoney(totalPrice) }}</div>
            </div>
            <router-link
              :to="{ name: 'body-detail', params: { id: 'detail-cart' } }"
              class="f jcc aic pt075 pb075 border-radius bg-brown white"
            >
              <div class="h5">Xem giỏ hàng</div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- Giỏ hàng -->
      <div class="icon-menu" style="z-index: 2000" @click="isShowMenu = true">
        <svg
          width="14"
          height="12"
          viewBox="0 0 14 12"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 0.75C0 0.34375 0.3125 0 0.75 0H13.25C13.6562 0 14 0.34375 14 0.75C14 1.1875 13.6562 1.5 13.25 1.5H0.75C0.3125 1.5 0 1.1875 0 0.75ZM0 5.75C0 5.34375 0.3125 5 0.75 5H13.25C13.6562 5 14 5.34375 14 5.75C14 6.1875 13.6562 6.5 13.25 6.5H0.75C0.3125 6.5 0 6.1875 0 5.75ZM13.25 11.5H0.75C0.3125 11.5 0 11.1875 0 10.75C0 10.3438 0.3125 10 0.75 10H13.25C13.6562 10 14 10.3438 14 10.75C14 11.1875 13.6562 11.5 13.25 11.5Z"
            fill="#1A1A1A"
          />
        </svg>
      </div>
    </div>
    <div class="aside-menu" :class="isShowMenu && 'active-menu'">
      <div v-if="isShowMenu" class="overlay" @click="isShowMenu = false"></div>
      <div class="menu-content pv1">
        <div v-if="!account" class="group-menu">
          <div
            class="h5 white cursor group-menu__button"
            v-for="(item, index) in dataNav"
            :key="index"
          >
            <router-link
              :to="{ name: 'body-view', params: { path: item.path } }"
            >
              <template v-if="$route.params.website === item.path">
                {{ item.title }}
              </template>
              <template v-else>{{ item.title }}</template>
            </router-link>
          </div>
          <div
            @click="(LoginPopup = true), (statusFormProp = 'register')"
            class="h5 white cursor group-menu__button"
          >
            Đăng ký
          </div>
          <div
            @click="(LoginPopup = true), (statusFormProp = 'login')"
            class="h5 white cursor group-menu__button"
          >
            Đăng nhập
          </div>
        </div>
        <div v-else class="group-menu white">
          <div
            v-if="account"
            class="action__container relative f aic border-radius ph1 gap1"
          >
            <div class="">
              <img
                class="image_user"
                :src="myAccount && myAccount.avatar"
                alt=""
              />
            </div>
            <div v-if="myAccount && myAccount.full_name !== ''" class="p4">
              {{ myAccount.full_name }}
            </div>
            <div v-if="myAccount && myAccount.full_name === ''" class="p4">
              {{ myAccount.user__username }}
            </div>
          </div>
          <router-link
            v-if="['ADMIN', 'AFFILIATE', 'CONSULTANT', 'EXPERT'].includes(roleUser)"
            :to="{ name: 'body-view', params: { path: 'quan-ly-user' } }"
            class="w100"
          >
            <div class="group-menu__button h4 w100">Về trang quản trị</div>
          </router-link>
          <router-link
            :to="{ name: 'body-view', params: { path: item.path } }"
            class="cursor w100"
            v-for="(item, index) in dataNav"
            :key="index"
          >
            <div
              class="h4 group-menu__button"
              v-if="$route.params.website === item.path"
            >
              {{ item.title }}
            </div>
            <div v-else class="h4 group-menu__button">{{ item.title }}</div>
          </router-link>
          <router-link
            :to="{ name: 'body-view', params: { path: 'product' } }"
            v-if="account"
          >
            <div
              v-if="$route.params.website === 'product'"
              class="h4 group-menu__button"
            >
              Shop
            </div>
            <div v-else class="h4 group-menu__button">Shop</div>
          </router-link>
          <div class="group-menu__button h4" @click="handleLogout">
            Đăng xuất
          </div>
        </div>
      </div>
    </div>

    <LoginView
      v-if="LoginPopup"
      @closePopup="closePopup"
      :statusFormProp="statusFormProp"
    />
  </div>
</template>

<script>
import LoginView from "@/views/LoginView/LoginView.vue";
import PopupLogout from "@/components/PopupLogout/LogoutView.vue";
import SubMenu from "@/components/SubMenu/SubMenu.vue";

import axios from "axios";

export default {
  components: {
    LoginView,
    PopupLogout,
    SubMenu,
  },

  data() {
    return {
      LoginPopup: false,
      statusFormProp: null,
      account: null,
      dataNav: [
        {
          title: "Kiến thức cho mẹ",
          path: "post-website",
          listChildren: [],
        },
        {
          title: "Forum",
          path: "forums",
        },
        {
          title: "Khoá học",
          path: "course-website",
        },
      ],
      myAccount: {},
      isShowMenu: false,
      roleUser: null,
      listCartcontainer: false,
      dataProductCart: [],
    };
  },

  
  computed: {
    isLogin() {
      return window.localStorage.user !== null;
    },

    handleTotlePrice() {
      var total = 0;

      if (this.dataProductCart !== null) {
        this.dataProductCart.forEach((item) => {
          let price = item.price_sale ? item.price_sale : item.price_product;
          total += price * item.quantity;
        });
      }

      return total;
    },
  },

  methods: {
    getDataAccount() {
      var account = localStorage.getItem("user");
      this.account = account;

      var myAccount = localStorage.getItem("myAccount");
      this.myAccount = JSON.parse(myAccount);
    },

    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },

    handleLogin() {
      var login = document.getElementsByClassName("log-out")[0];
      login.classList.toggle("active");
    },

    closePopup() {
      this.LoginPopup = false;
    },

    handleLogout() {
      // window.localStorage.clear();
      window.localStorage.removeItem("user");
      window.localStorage.removeItem("myAccount");
      window.localStorage.removeItem("idUser");
      window.localStorage.removeItem("role");
      if (this.$route.path !== "/")
        this.$router.push({ name: "website", params: { path: "/" } });
      else window.location.reload();
    },

    checkRoleUser() {
      const role = localStorage.getItem("role");
      this.roleUser = role;
    },

    async getDataCategory() {
      var url = "https://api.drmom.store/";
      await axios.get(url + "v1/api/categorypost/").then((res) => {
        this.dataNav[0].listChildren = this.renderTree(res.data.results);

        this.dataNav = [...this.dataNav];
      });
    },

    renderTree(data) {
      const map = {};
      const roots = [];

      data.forEach((item) => {
        map[item.id] = { ...item, children: [] };
      });

      data.forEach((item) => {
        if (item.category_parent) {
          map[item.category_parent]?.children.push(map[item.id]);
        } else {
          roots.push(map[item.id]);
        }
      });
      return roots;
    },

    updateProductCart() {
      let data = localStorage.getItem("cartProudct");
      let cart = JSON.parse(data);
      this.dataProductCart = cart;
    },

    converMoney(value) {
      var money = value.toLocaleString("it-IT", {
        style: "currency",
        currency: "VND",
      });
      return money;
    },

    handleDelete(item) {
      let data = localStorage.getItem("cartProudct");
      let cart = JSON.parse(data);

      for (let i = 0; i < cart.length; i++) {
        if (cart[i].id === item.id) {
          var itemCart = document.getElementById("product-" + item.id);
          itemCart.classList.toggle("delete-cart");
          cart.splice(i, 1);
        }
      }

      setTimeout(() => {
        localStorage.setItem("cartProudct", JSON.stringify(cart));
        this.updateProductCart()
        var cartShow = document.getElementsByClassName("delete-cart");
        for (let i = 0; i < cartShow.length; i++) {
          cartShow[i].classList.remove("delete-cart");
        }
      }, 400);
    },
  },

  mounted() {
    this.checkRoleUser();
    this.getDataAccount();
  },

  created() {
    this.getDataCategory();
    this.updateProductCart()

    var total = 0;

    if (this.dataProductCart !== null) {
      this.dataProductCart.forEach((item) => {
        let price = item.price_sale ? item.price_sale : item.price_product;
        total += price * item.quantity;
      });
    }

    this.totalPrice = total;
  },
};
</script>

<style scoped lang='scss'>
@import "HeaderLanding.scss";
</style>
<template>
  <div v-if="isShow" class="popup__container">
    <div class="popup__content" :class="paddingClass" :style="`width: ${width}`">
      <slot />
    </div>

    <div class="popup__bg" @click="close">
    </div>
  </div>
</template>

<script>
export default {
  name: 'PopupComponent',

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: '33.333%',
    },

    paddingClass: {
      type: String,
      default: 'p-5',
    },
  },

  data() {
    return {
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.popup {
  &__container {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 3001;
    position: fixed;
  }

  &__content {
    height: auto;
    z-index: 3001;
    width: 100%;
    position: absolute;
    background: white;
    border-radius: 8px;
    max-height: 85vh;
    overflow: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media screen and (max-width: 768px) {
      top: 50%;
      left: 50%;
      bottom: auto;
      width: 90vw !important;
      transform: translate(-50%, -50%);
    }
  }

  &__bg {
    z-index: 100;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.55);
    position: absolute;
  }
}
</style>

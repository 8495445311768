import axios from 'axios'

export const getToken = () => localStorage.getItem('user')

export const HTTP = axios.create({
  baseURL: `https://api.drmom.store/`,
  headers: {
    'Content-Type': 'application/json',
    ... getToken() ? {Authorization: 'Token ' + getToken()} : null,
  }
})

<template>
  <div class="container">
    <div class="container-header f aic mb2">
      <div class="qcont h2 pr1 border-right">Lịch hẹn</div>
      <div class="h5 pr05 pl1 brown">Quản lý lịch hẹn</div>
    </div>
    <div>
      <div class="filter-search f gap1">
        <div class="container-nav relative f jcb mb2" style="height: fit-content;">
          <div class="search-icon absolute">
            <div class="icon icon--search"></div>
          </div>
          <input
            v-model="search"
            class="pl2 pt075 pb075 pr075"
            type="text"
            style="width: 417px"
            :placeholder="'Tìm kiếm'"
          />
        </div>
        <div class="mb2 f aic gap05">
          <p class="h7" style="white-space: nowrap;">Từ ngày: </p>
          <DatePicker
            v-model="appointment_time"
            class="w100"
            type="date"
            placeholder="Chọn ngày hẹn"
            @input="getData"
          />
        </div>
        <div class="f aic mb2 gap1" style="flex-wrap: wrap">
          <div class="f aic gap05">
            <label for="paid">Đã thanh toán</label>
            <input v-model="paid" type="checkbox" id="paid" />
          </div>
          <p></p>
          <div class="f aic gap05">
            <label for="status">Đã xác nhận</label>
            <input v-model="status" type="checkbox" id="status" />
          </div>
        </div>

        <div v-if="paid || search || status || appointment_time" class="border border-brown brown bg-white-1 px05 h7 border-radius cursor" style="height: fit-content;" @click="abortFilter">
          Bỏ lọc
        </div>
      </div>

    </div>

    <div class="list-course f fdc gap1">
      <Loading :is-loading="isLoading" />

      <div
        v-for="(item, index) in dataSchedulesFilter"
        :key="index"
        class="course-item bg-white-1 px1 f gap1 cursor border-radius shadow"
      >
        <div class="f fdc">
          <p class="h4 brown">
            <span class="p5 black">Khách hàng:</span> {{ item.name }}
            <span class="gray-1-text p5 course-item-description"
              >({{ item.phone }})</span
            >
          </p>
          <div class="p6 gray-1-text">
            Trạng thái:
            <span class="h7" :class="item.status ? 'green' : 'red'">{{
              item.status ? "Đã xác nhận" : "Chưa xác nhận"
            }}</span>
            &nbsp; &nbsp;
            <!-- <template v-if="item.status">
              <p class="h7 cursor red" @click.stop="updateSchedule(false, item.id)">Từ chối</p>
            </template> -->
            <template v-if="item.status">
              <p>
                Thanh toán:
                <span class="h7" :class="item.paid ? 'green' : 'red'">{{
                  item.paid ? "Đã thanh toán" : "Chưa thanh toán"
                }}</span>
              </p>
              <span
                class="h7 cursor green pv025 ph05"
                style="border: 1px solid #69be31; border-radius: 8px"
                @click.stop="updateSchedule('paid', item.id)"
                >Xác nhận thanh toán</span
              >
            </template>
            <template v-else>
              <span
                class="h7 cursor green pv025 ph05"
                style="border: 1px solid #69be31; border-radius: 8px"
                @click.stop="updateSchedule('status', item.id)"
                >Xác nhận</span
              >
            </template>
          </div>
          <p class="gray-1-text p6">
            Thời gian hẹn:
            <span class="black h7">{{
              formatDate(item.appointment_time, "time")
            }}</span>
          </p>
          <!-- <p class="gray-1-text p6">
            Giá: <span class="black h7">{{ formatMoney(item.price || 0) }}</span>
          </p> -->
          <p v-if="item.expert" class="gray-1-text p6">
            Hẹn với chuyên gia:
            <span class="black h7">{{ item.expert.full_name }}</span>
          </p>
          <p v-if="item.question" class="gray-1-text p6">
            Nội dung câu hỏi:
            <span class="black h7 italic">"{{ item.question }}""</span>
          </p>
          <p class="gray-1-text p6">
            Thời gian tạo:
            <span class="black h7">{{ formatDate(item.created_at) }}</span>
          </p>
          <!-- <p v-if="item.user_created" class="gray-1-text p6">
            Người tạo:
            <span class="black h7">{{ item.user_created.username }}</span>
          </p> -->
        </div>
      </div>
    </div>

    <NotiView v-if="notiView" @closePopup="notiView = false" :title="title" />

    <Loading :isLoading="isLoading" :type="'page'" />
  </div>
</template>

<script>
import Loading from "@/components/Loading/Loading.vue";
import NotiView from "@/components/NotiView/NotiView.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { HTTP } from "../../http-default";

export default {
  name: "SchedulePage",

  components: {
    Loading,
    NotiView,
    DatePicker,
  },

  data() {
    return {
      dataSchedules: [],
      isLoading: true,

      notiView: false,
      logo: "noti-success",
      title: "",

      search: "",

      paid: false,
      status: false,
      appointment_time: null,
    };
  },

  computed: {
    dataSchedulesFilter() {
      let data = [...this.dataSchedules]
      if (this.search) {
        data = data.filter(
          (e) => e.name.toLowerCase().includes(this.search.toLowerCase()) || e.phone.includes(this.search)
        )
      }

      if (this.paid) {
        data = data.filter(
          (e) => e.paid
        )
      }

      if (this.status) {
        data = data.filter(
          (e) => e.status
        )
      }

      if (this.appointment_time) {
        data = data.filter(
          (e) => new Date(e.appointment_time) >= new Date(this.appointment_time)
        )
      }

      return data
    },

    roleUser () {
      const role = localStorage.getItem('role')
      return role
    },

    myAccount () {
      const myAccount = localStorage.getItem('myAccount')
      return myAccount ? JSON.parse(myAccount) : null
    },
  },

  created() {
    this.getData();
  },

  methods: {
    async getData() {
      let url = "v1/api/schedule/?depth=1"
      if (this.roleUser === 'EXPERT') url = `${url}&expert=${this.myAccount.id}`
      await HTTP.get(url).then((res) => {
        this.dataSchedules = res?.data?.results || [];
      });
      this.isLoading = false;
    },

    formatDate(date, _type) {
      const newDate = new Date(date);
      const dformat =
        (_type
          ? [
              newDate.getHours(),
              newDate.getMinutes() < 10
                ? "0" + newDate.getMinutes()
                : newDate.getMinutes(),
            ].join(":") + " "
          : "") +
        [
          newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate(),
          newDate.getMonth() + 1 < 10
            ? "0" + (newDate.getMonth() + 1)
            : newDate.getMonth() + 1,
          newDate.getFullYear(),
        ].join("/");

      return dformat;
    },

    formatMoney(value) {
      var x = value;
      x = x.toLocaleString("it-IT", { style: "currency", currency: "VND" });
      return x;
    },

    async updateSchedule(key, scheduleId) {
      const payload = {
        [key]: true,
      };

      await HTTP.patch(`v1/api/schedule/${scheduleId}/`, payload)
        .then(() => {
          this.title = "Cập nhật trạng thái lịch hẹn thành công";
          this.notiView = true;
          this.getData();
        })
        .catch((err) => {
          this.notiView = true;
          this.logo = "noti-error";
          this.title = err.message;
        });
    },

    abortFilter() {
      this.paid = false
      this.status = false
      this.appointment_time = null
      this.search = ''
    },
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  top: 0.85em;
  left: 0.3em;
}

@media screen and (max-width: 768px) {
  .filter-search {
    white-space: nowrap;
    flex-direction: column-reverse;

    .container-nav {
      width: 100%;
    }
    & input {
      width: 100% !important;
    }
  }
}
</style>
<template>
  <div class="sub-menu-container f fdc gap05">
    <div
      v-for="(menuItem, menuIndex) in dataMenu"
      :key="menuIndex"
      class="sub-menu f gap1"
      @mousemove="onMouseMove(menuIndex)"
      @mouseout="onMouseOut"
    >
      <p
        @click="$router.push(`/${path}/category-${menuItem.id}`)"
        class="sub-item-menu"
        :class="routeCategoryId == menuItem.id && 'brown h5'"
      >
        {{ menuItem.category_name }}
      </p>
      <div
        v-if="menuItem.children && menuItem.children.length > 0"
        class="f gap05 sub-item-menu-child"
        :class="show === menuIndex && 'show-menu'"
      >
        <div class="connect"></div>
        <div class="">
          <SubMenu :dataMenu="menuItem.children" :path="path" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    dataMenu: {
      type: Array,
      default: () => [],
    },

    path: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      menu: [],
      show: false,
    };
  },

  watch: {
    // children: {
    //   handler() {
    //     this.children.forEach(e => {
    //       const index = this.menu.findIndex(x => x.id === e.id);
    //       this.menu.splice(index, 1);
    //     })
    //   },
    //   deep: true,
    // }
  },

  computed: {
    routeCategoryId() {
      return this.$route.params.id?.split("-")?.[1];
    },
  },

  methods: {
    onMouseMove(index) {
      this.show = index
      if (this.time) clearTimeout(this.time)
    },

    onMouseOut() {
      if (!this.time) {
        this.time = setTimeout(() => {
          this.show = null
        }, 1000 * 3)
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.item-menu {
  bottom: -0.5em;
  left: 0;
  transform: translateY(100%);
}

.item-menu-title,
.sub-item-menu {
  position: relative;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    &::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #5e2d2d;
    top: 1.5em;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
}

.connect {
  width: 30px;
  height: auto;
  // border-bottom-left-radius: 5px;
  border-left: 1px solid #5e2d2d;
  // border-bottom: 1px solid #5e2d2d;
}

.show-menu {
  width: 100% !important;
  height: auto !important;
  transition: 0.5s;
}

.sub-item-menu-child {
  width: 0;
  height: 0;
  transition: 0.5s;
  overflow: hidden;
  // position: absolute;
  // top: 0;
  // right: -100%;
}
</style>
<template>
  <div v-if="role === 'USER' || !role" class="chat-widget f aic jcc shadow cursor">
    <div class="relative">
      <img
        src="@/assets/icons/chat.svg"
        style="width: 40px; height: 40px; object-fit: cover"
        @click.prevent="isActive = !isActive"
      />

      <div
        class="widget_container shadow absolute"
        :class="isActive && 'active-widget'"
      >
        <div class="bg-white w100 px1">
          <img
            src="@/components/Landingpage/imgLanding/logo.png"
            alt=""
            style="height: 40px"
          />
          <p class="h7">Dr.Mom Phan Hồng Thu</p>
          <p class="h8 brown">Mẹ luôn là bác sĩ tốt nhất</p>
        </div>

        <div v-if="error" class="red h7 italic">
          {{ error }}
        </div>
        <div
          class="w100 bg-gray-2 border-radius f aic jcc mb1"
          style="height: 300px"
        >
          <div v-if="step === 1" class="f fdc jcc aic gap1">
            <button
              class="bg-brown ph1 pv05 white border-radius h7"
              style="width: fit-content"
              @click="startConversation"
            >
              Bắt đầu
            </button>
            <p class="h7">Nhấn bắt đầu để bắt đầu chat với CSKH</p>
          </div>

          <div v-if="step === 2" class="f fdc jcc aic gap1">
            <input
              v-model="guestUser.name"
              type="text"
              placeholder="Nhập tên của bạn"
              style="width: 250px"
            />
            <input
              v-model="guestUser.phone"
              type="text"
              placeholder="Nhập Số điện thoại"
              style="width: 250px"
            />
            <button
              class="bg-brown ph1 pv05 white border-radius h7"
              style="width: fit-content"
              @click="handleCreateGuestUser"
            >
              Tiếp tục
            </button>
          </div>

          <div v-if="step === 3" class="f fdc w100 px1 bg-white-1">
            <p>
              Xin chào,
              <span class="h5 brown">{{
                myAccount ? myAccount.full_name : dataGuestUser.name
              }}</span>
              Drmom có thể giúp gì cho bạn ?
            </p>
            <p v-if="!idUser" class="h7 brown cursor" @click="handleNewConversation">
              Đoạn chat mới
            </p>
            <div class="chat bg-gray-2 border-radius px1 relative">
              <!-- <div class="chat__header bg-white-1 f gap1">
                <div class="avatar">
                  <img
                    v-if="
                      conversationActive.receiver &&
                      conversationActive.receiver.customer
                    "
                    :src="
                      conversationActive.receiver.customer.avatar ||
                      './avatar.jpeg'
                    "
                    alt="Hello"
                  />
                  <img v-else src="./avatar.jpeg" alt="" />
                </div>
                <p
                  v-if="
                    conversationActive.receiver &&
                    conversationActive.receiver.customer
                  "
                  class="h6"
                >
                  {{ conversationActive.receiver.customer.full_name }}
                </p>
                <p v-else class="h6">Người dùng không xác định</p>
              </div> -->
              <div class="chat__input f gap05">
                <input
                  v-model="inputMessage"
                  placeholder="Nhập tin nhắn"
                  style="height: 40px; width: 100%"
                  @keyup.enter="handleSentMessage"
                />
                <button
                  class="bg-brown ph1 border-radius white h7"
                  style="height: 40px"
                  @click="handleSentMessage"
                >
                  Gửi
                </button>
              </div>

              <div
                id="message_wraper"
                class="message__container-widget f fdc gap05"
              >
                <div
                  v-for="(itemMessage, index) in dataMessageOfConversation"
                  :key="index"
                  class="chat__message f w100"
                  :class="
                    itemMessage.sender_guest ===
                    (idUser || dataGuestUser.id)
                      ? 'jce'
                      : ''
                  "
                >
                  <div
                    class="f aic gap1"
                    :class="
                      itemMessage.sender_guest ===
                      (idUser || dataGuestUser.id)
                        ? 'revert'
                        : ''
                    "
                  >
                    <div
                      class="message relative flex gap1 aic"
                      :class="[
                        itemMessage.sender_guest ===
                        (idUser || dataGuestUser.id)
                          ? 'message--me'
                          : 'message--partner',
                        itemMessage.type === 'NOTI' && 'message--noti',
                      ]"
                    >
                      {{ itemMessage.text }}
                    </div>
                    <span class="h6 gray" style="bottom: -1em; left: 0">{{
                      formatDate(itemMessage.created_at)
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
import WebSocketService from "@/javascript/ws-message.js";

export default {
  name: "ChatWidget",

  data() {
    return {
      isActive: false,

      guestUser: {
        name: "",
        phone: "",
      },
      step: 1,
      error: null,
      dataMessage: [],

      dataGuestUser: null,
      conversation: null,

      inputMessage: "",
      isLoadingMessage: false,
      pageMessage: 1,
      limitMessage: 100,
      dataMessageOfConversation: [],
    };
  },

  computed: {
    myAccount() {
      return localStorage.getItem("myAccount")
        ? JSON.parse(localStorage.getItem("myAccount"))
        : null;
    },

    role() {
      return localStorage.getItem("role");
    },

    idUser() {
      return localStorage.getItem("idUser");
    }
  },

  watch: {
    step: {
      handler(val) {
        if (val === 3) this.getMessageOfConversation();
      },
      deep: true,
    },
  },

  created() {
    if (this.myAccount) {
      this.getConversationOfCustomer();
    } else {
      this.dataGuestUser = localStorage.getItem("guestUserChat")
        ? JSON.parse(localStorage.getItem("guestUserChat"))
        : null;
      this.conversation = localStorage.getItem("guestConversation")
        ? JSON.parse(localStorage.getItem("guestConversation"))
        : null;
      this.step = this.dataGuestUser ? 3 : 1;
    }
  },

  methods: {
    startConversation() {
      if (this.myAccount) {
        this.createConversation();
      } else {
        this.step = 2;
      }
    },

    async handleCreateGuestUser() {
      const payload = { ...this.guestUser };
      this.error = null;
      this.guestUser = {
        phone: "",
        name: "",
      };

      await HTTP.post("/chat/create-guest-user/", payload)
        .then((res) => {
          const data = res?.data?.data || null;
          this.dataGuestUser = data;
          if (data) {
            localStorage.setItem("guestUserChat", JSON.stringify(data));
          }

          this.createConversation();
        })
        .catch((err) => {
          this.error = err?.response?.data?.data || err?.message;
        });
    },

    async createConversation() {
      this.error = null;
      const payload = {
        name: "",
        type: "CUSTOMER",
        ...(this.myAccount
          ? { add_list_user: [this.idUser] }
          : { guest_user: this.dataGuestUser.id }),
      };
      await HTTP.post("/chat/conversation/", payload)
        .then((res) => {
          const data = res?.data?.data || null;
          this.conversation = data;
          if (data && !this.myAccount) {
            localStorage.setItem("guestConversation", JSON.stringify(data));
          }
          this.step = 3;
        })
        .catch((err) => {
          this.error = err?.response?.data?.data || err?.message;
        });
    },

    async getConversationOfCustomer() {
      this.error = null;
      await HTTP.get("/chat/conversation-of-customer/")
        .then((res) => {
          const data = res?.data?.data || null;
          if (data) {
            this.conversation = data;
            this.step = 3;
          } else {
            this.step = 1;
          }
        })
        .catch((err) => {
          this.error = err?.response?.data?.data || err?.message;
        });
    },

    async getMessageOfConversation() {
      WebSocketService.connectConversation(this.conversation.id);

      WebSocketService.socketConversation.onmessage = (event) => {
        const data = JSON.parse(event.data);
        this.dataMessageOfConversation = data?.messages || [];
      };
    },

    async handleSentMessage() {
      if (this.inputMessage.trim()) {
        const payload = {
          text: this.inputMessage.trim(),
          conversation: this.conversation.id,
          ...(this.myAccount
          ? { sender: this.idUser }
          : { sender_guest: this.dataGuestUser.id }),
         
        };
        this.inputMessage = "";
        await this.myAccount 
        ? HTTP.post("/chat/create-message/", payload)
        : HTTP.post("/chat/create-guest-message/", payload)
      }
    },

    handleNewConversation() {
      localStorage.removeItem("guestUserChat");
      localStorage.removeItem("guestConversation");
      this.step = 1;
      this.dataGuestUser = null;
      this.conversation = null;
      this.error = null;
      this.dataMessageOfConversation = [];
      WebSocketService.socketConversation.onclose();
    },

    formatDate(date) {
      const newDate = new Date(date);
      const now = new Date();

      const dformat =
        [
          newDate.getHours(),
          newDate.getMinutes() < 10
            ? "0" + newDate.getMinutes()
            : newDate.getMinutes(),
        ].join(":") +
        " " +
        (now.getDate() === newDate.getDate() &&
        now.getMonth() === newDate.getMonth()
          ? ""
          : [
              newDate.getDate() < 10
                ? "0" + newDate.getDate()
                : newDate.getDate(),
              newDate.getMonth() + 1 < 10
                ? "0" + (newDate.getMonth() + 1)
                : newDate.getMonth() + 1,
              newDate.getFullYear(),
            ].join("/"));

      return dformat;
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-widget {
  z-index: 10000;
  position: fixed;
  bottom: 2em;
  right: 2em;
  width: 60px;
  aspect-ratio: 1;
  background: #fff;
  border-radius: 50%;
}

.active-widget {
  opacity: 1 !important;
  display: block !important;
}

.widget_container {
  display: none;
  opacity: 0;
  top: -1em;
  right: 0;
  transform: translate(0%, -100%);
  width: 400px;
  background: #fff;
  border-radius: 0.5em;
  overflow: hidden;

  @media screen and (max-width: 768px) {
    width: 90vw;
    right: -1em;
  }
}

.message__container-widget {
  height: 100%;
  width: 100%;
  flex-direction: column-reverse;
  overflow: auto;
  padding: 2px 2px 3em 2px;
}

.chat {
  height: 250px;
  &__message {
    // max-width: 40vw;
  }

  &__header {
    position: absolute;
    top: 0.1em;
    right: 0.1em;
    left: 0.1em;
    border-radius: 0.5em;
    padding: 0.5em;
  }

  &__input {
    position: absolute;
    bottom: 0.1em;
    right: 0.1em;
    left: 0.1em;
    border-radius: 0.5em;
    padding: 0.5em;
    z-index: 100;
  }
}

.message {
  padding: 0.5em;
  border-radius: 0.5em;
  max-width: 300px;
  word-wrap: break-word;

  @media screen and (max-width: 768px) {
    max-width: 70vw;
  }

  &--me {
    background: #6f382b;
    color: #fff;
  }

  &--partner {
    background: #fff;
  }

  &--noti {
    background: none;
    color: #757575;
  }
}

.revert {
  flex-direction: row-reverse;
}

::-webkit-scrollbar {
  width: 2px; /* Đặt chiều rộng cho thanh cuộn dọc */
  height: 2px; /* Đặt chiều cao cho thanh cuộn ngang */
}
::-webkit-scrollbar-corner {
  background-color: transparent; /* Không hiển thị phần góc dưới cùng */
}
</style>
import { render, staticRenderFns } from "./Chatpage.vue?vue&type=template&id=0e2adf18&scoped=true"
import script from "./Chatpage.vue?vue&type=script&lang=js"
export * from "./Chatpage.vue?vue&type=script&lang=js"
import style0 from "./Chatpage.vue?vue&type=style&index=0&id=0e2adf18&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e2adf18",
  null
  
)

export default component.exports
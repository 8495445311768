<template>
    <div class="">
        <div 
            class="card-item border-radius mb1 bg-white-1" 
            v-for="(item, index) in dataSelect" 
            :key="index">
            <router-link :to=" { name: 'body-detail', params: { id: item.id } } ">
                <div class="card-item__header f jcb aic pt05 pb05 pl1 pr1 border-bottom">
                    <h5 class="red">{{item.title_gift}}</h5>
                    <div class="card-item__status h7 pt025 pb025 pl05 pr05 border-radius">
                        Đang chạy
                    </div>
                </div>
                <div class="card-item__body cursor pt1 pb1 pl1 pr1">
                    <div class="f jcb aic mb05">
                        <div class="gray h6">Loại quà</div>
                        <div class="h6">{{item.gift_for_customer ? 'Quà tặng đổi điểm' : 'Quà tặng đơn hàng'}}</div>
                    </div>

                    <div v-if="item.gift_for_customer" class="f jcb aic mb05">
                        <div class="gray h6">Điểm quy đổi</div>
                        <div class="h6">{{item.exchange_point}} điểm thành viên</div>
                    </div>
                    <!-- <div class="f jcb aic mb05">
                        <div class="gray h6">Điều kiện nhận</div>
                        <div class="h6">{{item.require_gift.length}} Điều kiện</div>
                    </div> -->
                    <div class="f jcb aic mb05">
                        <div class="gray h6">Số lượng</div>
                        <div 
                            v-if="item.quantity_gift == 0"
                            class="h6">Không giới hạn</div>
                        <div 
                            v-else
                            class="h6">{{item.quantity_gift}}</div>
                    </div>
                    <div class="f jcb aic mb05">
                        <div class="gray h6">Hạn sử dụng</div>
                        <div class="p4">{{ item.time_end ? formatTime(item.time_end) : 'Không có'}}</div>
                    </div>
                </div>
                <div class="card-item__total f jcb aic pt1 pb1 pr1 pl1">
                    <div class="f fdc">
                        <div class="h7 mb05">Tổng giá trị quà</div>
                        <div class="p3 brown">{{formatMoney(item.total_price_gift || item.discount_order)}}</div>
                    </div>
                    <!-- <div @click="loadStatusCard" class="h5 pt075 pb075 pl1 pr1 border border-radius bg-white-1">
                        Xem chi tiết
                    </div> -->
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
import { HTTP } from '../../http-default'

export default {
    props: {
        titleSelect: String,
    },
    data() {
        return {
            data : [],
            dataSelect: [],
        }
    },
    watch: {
        'titleSelect' : function() {
            this.dataSelect = []
            if(this.titleSelect == 'Tất cả') {
                for(let i = 0; i < this.data.length; i++) { 
                    this.dataSelect.push(this.data[i])
                }
            }
            else {
                for(let i = 0; i < this.data.length; i++) {
                    if(this.data[i].status == this.titleSelect) {
                        this.dataSelect.push(this.data[i])
                        console.log(this.dataSelect)
                    }
                }
            }
            setTimeout(() => {
                this.loadStatusCard()
            }, 1)
        }
    },
    methods: {
        // formatTime
        formatTime(time) {
            var timeFormat = time.split('T')
            timeFormat[1] = timeFormat[1].split('')
            timeFormat[1] = timeFormat[1].splice(0, 8)
            timeFormat[1] = timeFormat[1].join("")

            return timeFormat[1] + ' ' + timeFormat[0]
        },

        // formatMoney
        formatMoney(value) {
            var x = value;
            x = x.toLocaleString('it-IT', {style : 'currency', currency : 'VND'});
            return x
        },
        
        getData() {
            HTTP.get('v1/api/gift/')
            .then((res) => {
                this.data = res.data.results
                this.dataSelect = this.data
                this.dataSelect.forEach(item => {
                    item.require_gift = item.require_gift.replace(/\//g,'');
                    item.require_gift = JSON.parse(item.require_gift)
                })
            })
        }
    },
    created() {
        this.getData()
    },
}
</script>

<style scoped lang="scss">
@import "Card.scss"
</style>
<template>
  <div class="level-customer__list w100 px1 bg-white-1 border-radius mv1">
    <div>
      <h3 class="gray">Danh sách cấp độ khách hàng</h3>
    </div>
    <div class="f jce">
      <div
        class="white p4 px05 border-radius border bg-brown cursor"
        @click="isShowPopupAddLevelCustomer = true"
      >
        Thêm mới cấp độ tiếp theo
      </div>
    </div>
    <div class="f fdc gap1">
      <div
        v-for="(item, index) in dataLevels"
        :key="index"
        class="level-customer__item"
      >
        <div class="f gap1">
          <h5
            class="border-radius pv05 ph1 white align-c cursor"
            :style="`background: ${item.color}`"
            style="width: 150px; height: fit-content"
            @click="handleOpenPopupEdit(item)"
          >
            {{ item.title_level_customer }}
          </h5>
          <div
            class="border-radius px1"
            :style="`border: 1px solid ${item.color}`"
            style="min-width: 200px; height: auto"
          >
            <p class="h7">
              {{ item.conditions }}
            </p>
          </div>
        </div>
        <div
          v-if="index != dataLevels.length - 1"
          class="relative"
          style="
            height: 45px;
            width: 2px;
            border-radius: 0.5px;
            background: #d6c0b3;
          "
        >
          <span
            style="
              top: -2px;
              right: -3px;
              width: 0;
              height: 0;
              border-left: 4px solid transparent;
              border-right: 4px solid transparent;
              border-bottom: 8px solid #d6c0b3;
            "
            class="absolute"
          ></span>
        </div>
      </div>
    </div>

    <Popup
      :is-show="isShowPopupAddLevelCustomer"
      :width="'30vw'"
      @close="handleClosePopup"
    >
      <div
        class="bg-white-1 px1 border-radius f fdc gap05 relative"
        style="padding-bottom: 80px; height: 80vh; overflow: auto"
      >
        <div
          class="f jce w100 px1 border-top fixed bg-white-1"
          style="bottom: 0; left: 0; right: 0; z-index: 10"
        >
          <button
            class="bg-brown ph15 pv075 border-radius white p5 cursor"
            @click="dataCreateLevel.id ? updateLevel() : createLevel()"
          >
            Lưu lại
          </button>
        </div>

        <h5>
          {{ dataCreateLevel.id ? "Cập nhật" : "Thêm mới" }} cấp độ khách hàng
        </h5>

        <div class="w100">
          <p class="h7 mb025" for="">Tiêu đề cấp độ</p>
          <input
            class="pt075 pb075 pr075 pl075 w100"
            type="text"
            placeholder="Nhập tiêu đề"
            v-model="dataCreateLevel.title_level_customer"
          />
        </div>

        <div class="w100">
          <p class="h7 mb025" for="">Màu sắc hiển thị</p>
          <input
            class="w100"
            style="padding: 0 !important"
            type="color"
            v-model="dataCreateLevel.color"
          />
        </div>

        <div class="w100">
          <p class="h7 mb025" for="">Mô tả điều kiện</p>
          <textarea
            class="pt075 pb075 pr075 pl075 w100 border-radius"
            placeholder="Nhập mô tả"
            row="3"
            style="resize: none"
            v-model="dataCreateLevel.conditions"
          ></textarea>
        </div>
      </div>
    </Popup>

    <NotiView v-if="notiView" @closePopup="notiView = false" :title="title" />
  </div>
</template>

<script>
import { HTTP } from "../../http-default";
import Popup from "@/components/Popup/index.vue";
import NotiView from "@/components/NotiView/NotiView.vue";

export default {
  name: "LevelCustomerPage",

  components: {
    Popup,
    NotiView,
  },

  data() {
    return {
      dataLevels: [],

      dataCreateLevel: {
        title_level_customer: "",
        color: "",
        conditions: "",
      },

      notiView: false,
      title: "",

      isShowPopupAddLevelCustomer: false,
    };
  },

  created() {
    this.getData();
  },

  methods: {
    getData() {
      HTTP.get("v1/api/levelcustomer/").then((res) => {
        this.dataLevels = res.data?.results || [];
        this.dataLevels = this.dataLevels.sort(
          (a, b) => (b?.level || 0) - (a?.level || 0)
        );
      });
    },

    handleOpenPopupEdit(item) {
      this.dataCreateLevel = {...item};
      this.isShowPopupAddLevelCustomer = true;
    },

    handleClosePopup() {
      this.isShowPopupAddLevelCustomer = false;
      this.dataCreateLevel = {
        title_level_customer: "",
        color: "",
        conditions: "",
      };
    },

    createLevel() {
      const payload = {
        ...this.dataCreateLevel,
      };
      HTTP.post("v1/api/levelcustomer/", payload)
        .then(() => {
          this.title = "Thêm mới cấp độ thành công";
          this.notiView = true;
          this.isShowPopupAddLevelCustomer = false;
          this.dataCreateLevel = {
            title_level_customer: "",
            color: "",
            conditions: "",
          };
          this.getData();
        })
        .catch(() => {
          this.notiView = true;
          this.logo = "noti-error";
          this.title = "Thêm mới cấp độ thất bại";
        });
    },

    updateLevel() {
      const payload = {
        ...this.dataCreateLevel,
      };
      HTTP.patch(`v1/api/levelcustomer/${this.dataCreateLevel.id}/`, payload)
        .then(() => {
          this.title = "Chỉnh sửa cấp độ thành công";
          this.notiView = true;
          this.dataCreateLevel = {
            title_level_customer: "",
            color: "",
            conditions: "",
          };
          this.isShowPopupAddLevelCustomer = false;
          this.getData();
        })
        .catch(() => {
          this.notiView = true;
          this.title = "Chỉnh sửa cấp độ thất bại";
        });
    },
  },
};
</script>

<style>
</style>
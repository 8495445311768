var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-page",class:_vm.isMobile ? '' : 'ph2'},[(!_vm.isMobile)?_c('div',{staticClass:"container-header f aic mb2"},[_c('div',{staticClass:"qcont h2 pr1 border-right"},[_vm._v("Trò chuyện")]),_c('div',{staticClass:"h5 pr05 pl1 brown"},[_vm._v("Quản lý trò chuyện")])]):_vm._e(),_c('div',{staticClass:"f gap1 bg-white-1 border-radius px1 h100"},[((_vm.isMobile && !_vm.conversationActive) || !_vm.isMobile)?_c('div',{staticClass:"conversation f fdc gap1",style:(("width: " + (_vm.isMobile ? '100%' : '30%')))},[(_vm.role === 'ADMIN' || _vm.role === 'CONSULTANT')?_c('div',{staticClass:"conversation__container w100"},[_c('h4',[_vm._v("Tin nhắn khách hàng")]),_vm._l((_vm.listConversationsCustomer),function(item,index){return _c('div',{key:index,staticClass:"conversation__item f gap1 px1 border-radius",class:_vm.conversationActive &&
            _vm.conversationActive.id === item.id &&
            'bg-gray-2',on:{"click":function($event){return _vm.getMessageOfConversation(item)}}},[_c('div',{staticClass:"avatar"},[(item.receiver && item.receiver.customer)?_c('img',{attrs:{"src":item.receiver.customer.avatar || './avatar.jpeg',"alt":"Hello"}}):_c('img',{attrs:{"src":require("./avatar.jpeg"),"alt":""}})]),_c('div',[(item.receiver && item.receiver.phone)?_c('p',{staticClass:"h6"},[_vm._v(" "+_vm._s(item.receiver.name)+" "),_c('span',{staticClass:"gray italic"},[_vm._v("("+_vm._s(item.receiver.phone)+")")])]):_vm._e(),(item.receiver && item.receiver.customer)?_c('p',{staticClass:"h6"},[_vm._v(" "+_vm._s(item.receiver.customer.full_name)+" "),_c('span',{staticClass:"gray italic"},[_vm._v("(Khách hàng hệ thống)")])]):_vm._e(),(item.latest_message)?_c('p',{staticClass:"h7 ellipsis"},[(item.latest_message.sender == _vm.idUser)?_c('span',{staticClass:"gray"},[_vm._v("Bạn: ")]):_vm._e(),_vm._v(" "+_vm._s(item.latest_message.text)+" ")]):_c('p',{staticClass:"h7 gray"},[_vm._v("Chưa có tin nhắn")])])])})],2):_vm._e(),_c('div',{staticClass:"conversation__container w100"},[_c('div',{staticClass:"f jcb"},[_c('h4',[_vm._v("Tin nhắn nội bộ")]),_c('p',{staticClass:"h4 brown cursor",on:{"click":function($event){_vm.isShowPopupUser = true}}},[_vm._v("+")])]),_vm._l((_vm.listConversationsInternal),function(item,index){return _c('div',{key:index,staticClass:"conversation__item f gap1 px1 border-radius",class:_vm.conversationActive &&
            _vm.conversationActive.id === item.id &&
            'bg-gray-2',on:{"click":function($event){return _vm.getMessageOfConversation(item)}}},[_c('div',{staticClass:"avatar"},[(item.receiver && item.receiver.customer)?_c('img',{attrs:{"src":item.receiver.customer.avatar || './avatar.jpeg',"alt":"Hello"}}):_c('img',{attrs:{"src":require("./avatar.jpeg"),"alt":""}})]),_c('div',[(item.receiver && item.receiver.customer)?_c('p',{staticClass:"h6"},[_vm._v(" "+_vm._s(item.receiver.customer.full_name)+" ")]):_c('p',{staticClass:"h6"},[_vm._v("Người dùng không xác định")]),(item.latest_message)?_c('p',{staticClass:"h7 ellipsis"},[(item.latest_message.sender == _vm.idUser)?_c('span',{staticClass:"gray"},[_vm._v("Bạn: ")]):_vm._e(),_vm._v(" "+_vm._s(item.latest_message.text)+" ")]):_c('p',{staticClass:"h7 gray"},[_vm._v("Chưa có tin nhắn")])])])})],2)]):_vm._e(),(_vm.conversationActive)?_c('div',{staticClass:"chat bg-gray-2 border-radius px1 relative",style:(("width: " + (_vm.isMobile ? '100%' : '70%')))},[_c('div',{staticClass:"chat__header bg-white-1 f gap1",on:{"click":function($event){_vm.conversationActive = null}}},[_c('div',{staticClass:"avatar f aic gap05"},[(_vm.isMobile)?_c('span',{staticClass:"h3"},[_vm._v(" < ")]):_vm._e(),(
              _vm.conversationActive.receiver &&
              _vm.conversationActive.receiver.customer
            )?_c('img',{attrs:{"src":_vm.conversationActive.receiver.customer.avatar || './avatar.jpeg',"alt":"Hello"}}):_c('img',{attrs:{"src":require("./avatar.jpeg"),"alt":""}})]),(
            _vm.conversationActive.receiver &&
            _vm.conversationActive.receiver.customer
          )?_c('p',{staticClass:"h6"},[_vm._v(" "+_vm._s(_vm.conversationActive.receiver.customer.full_name)+" ")]):(
            _vm.conversationActive.receiver && _vm.conversationActive.receiver.phone
          )?_c('p',{staticClass:"h6"},[_vm._v(" "+_vm._s(_vm.conversationActive.receiver.name)+" "),_c('span',{staticClass:"gray italic"},[_vm._v("("+_vm._s(_vm.conversationActive.receiver.phone)+")")])]):_c('p',{staticClass:"h6"},[_vm._v("Người dùng không xác định")])]),(
          _vm.conversationActive.type === 'CUSTOMER' &&
          !_vm.checkUserJoinedConversation
        )?_c('p',{staticClass:"chat__input bg-white-1 p6"},[_vm._v(" Bạn chưa tham gia cuộc trò chuyện này. Nhấn "),_c('span',{staticClass:"cursor h7 brown",on:{"click":_vm.handleJoinConversation}},[_vm._v("Tham gia")]),_vm._v(" để tham gia cuộc trò chuyện! ")]):_c('div',{staticClass:"chat__input f gap05"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputMessage),expression:"inputMessage"}],staticStyle:{"height":"40px","width":"100%"},attrs:{"placeholder":"Nhập tin nhắn"},domProps:{"value":(_vm.inputMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleSentMessage.apply(null, arguments)},"input":function($event){if($event.target.composing){ return; }_vm.inputMessage=$event.target.value}}}),_c('button',{staticClass:"bg-brown ph1 border-radius white h7",staticStyle:{"height":"40px"},on:{"click":_vm.handleSentMessage}},[_vm._v(" Gửi ")])]),_c('div',{staticClass:"message__container f fdc gap05",attrs:{"id":"message_wraper"}},_vm._l((_vm.dataMessageOfConversation),function(itemMessage,indexMessage){return _c('div',{key:itemMessage.id,staticClass:"chat__message f w100 gap05",class:[
            itemMessage.sender === _vm.idUser ? 'revert' : '',
            itemMessage.type === 'NOTI' && 'jcc aic gray-text' ]},[_c('div',{staticClass:"avatar--small f fdc jce",style:(indexMessage !== 0 &&
              _vm.dataMessageOfConversation[indexMessage - 1].sender ===
                itemMessage.sender &&
              'opacity: 0;')},[(
                _vm.findUserMessage(itemMessage.sender) &&
                _vm.findUserMessage(itemMessage.sender).customer
              )?_c('img',{attrs:{"src":_vm.findUserMessage(itemMessage.sender).customer.avatar ||
                './avatar.jpeg',"alt":"Hello"}}):_c('img',{attrs:{"src":require("./avatar.jpeg"),"alt":""}})]),_c('div',{staticClass:"f aic gap1",class:itemMessage.sender === _vm.idUser ? 'revert' : ''},[_c('div',{staticClass:"message relative flex gap1 aic",class:[
                itemMessage.sender === _vm.idUser
                  ? 'message--me'
                  : 'message--partner',
                itemMessage.type === 'NOTI' && 'message--noti' ],on:{"click":function($event){itemMessage.isShowInfo = !itemMessage.isShowInfo}}},[(
                  itemMessage.sender !== _vm.idUser &&
                  _vm.findUserMessage(itemMessage.sender) &&
                  _vm.findUserMessage(itemMessage.sender).customer
                )?_c('p',{staticClass:"p7 gray mb025",style:(indexMessage !== 0 &&
                  _vm.dataMessageOfConversation[indexMessage - 1].sender ===
                    itemMessage.sender &&
                  'display: none;')},[_vm._v(" "+_vm._s(_vm.findUserMessage(itemMessage.sender).customer.full_name)+" ")]):_vm._e(),_vm._v(" "+_vm._s(itemMessage.text)+" ")]),(itemMessage.isShowInfo)?_c('span',{staticClass:"h6 gray",staticStyle:{"bottom":"-1em","left":"0"}},[_vm._v(_vm._s(_vm.formatDate(itemMessage.created_at)))]):_vm._e()])])}),0)]):_vm._e()]),_c('Loading',{attrs:{"is-loading":_vm.isLoading,"type":"page"}}),(_vm.notiView)?_c('NotiView',{attrs:{"title":_vm.title,"logo":_vm.logo},on:{"closePopup":function($event){_vm.notiView = false}}}):_vm._e(),_c('Popup',{attrs:{"is-show":_vm.isShowPopupUser,"width":'30vw'},on:{"close":function($event){_vm.isShowPopupUser = false}}},[_c('div',{staticClass:"bg-white-1 px1 border-radius f fdc gap05 relative",staticStyle:{"padding-bottom":"80px","height":"80vh"}},[_c('div',{staticClass:"f jce w100 px1 border-top fixed bg-white-1 border-radius",staticStyle:{"bottom":"0","left":"0","right":"0","z-index":"10"}},[_c('button',{staticClass:"bg-brown ph15 pv075 border-radius white p5 cursor",on:{"click":_vm.handleAddConvention}},[_vm._v(" Xác nhận ")])]),_c('p',{staticClass:"h4"},[_vm._v("Chọn thành viên")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchUser),expression:"searchUser"}],staticClass:"mv1",attrs:{"type":"text","placeholder":"Tìm kiếm"},domProps:{"value":(_vm.searchUser)},on:{"input":function($event){if($event.target.composing){ return; }_vm.searchUser=$event.target.value}}}),_c('div',{staticClass:"f fdc gap1",staticStyle:{"overflow":"auto"}},_vm._l((_vm.dataUsers.filter(
            function (e) { return e.username.includes(_vm.searchUser) ||
              (e.customer && e.customer.full_name.includes(_vm.searchUser)); }
          )),function(item,index){return _c('label',{key:index,staticClass:"px1 border-radius f aic gap1",class:_vm.userSelected && _vm.userSelected.id === item.id
              ? 'border-brown'
              : 'border',on:{"click":function($event){_vm.userSelected = item}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.isSelected),expression:"item.isSelected"}],attrs:{"type":"radio","name":"user"},domProps:{"checked":_vm._q(item.isSelected,null)},on:{"change":function($event){return _vm.$set(item, "isSelected", null)}}}),_c('div',{staticClass:"avatar"},[(item.customer && item.customer.avatar)?_c('img',{attrs:{"src":item.customer.avatar || './avatar.jpeg',"alt":"Hello"}}):_c('img',{attrs:{"src":require("./avatar.jpeg"),"alt":""}})]),(item.customer)?_c('p',{staticClass:"p5"},[_vm._v(" "+_vm._s(item.customer.full_name)+" ")]):_c('p',{staticClass:"p5"},[_vm._v(" "+_vm._s(item.username)+" ")])])}),0)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
@<template>
    <div 
        class="log-out absolute border-radius bg-white-1">

        <!-- user customer -->
        <div 
            v-if="roleUser === 'USER'"
            class="w100 f fdc">
            <router-link 
                :to="{ name : 'body-view' , params : {path : 'myAccount'} }" class="w100 log-out__item f aic cursor">
                <div style="width: 24px; height: 24px">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#757575" d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/></svg>
                </div>
                <div class="log-out__item-title h5 ml05 cursor">Tài khoản của tôi</div>
            </router-link>
            <router-link 
                :to="{ name : 'body-view' , params : {path : 'myOrder'} }" class="w100 log-out__item f aic cursor">
                <div style="width: 24px; height: 24px">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#757575" d="M20.49,7.52a.19.19,0,0,1,0-.08.17.17,0,0,1,0-.07l0-.09-.06-.15,0,0h0l0,0,0,0a.48.48,0,0,0-.09-.11l-.09-.08h0l-.05,0,0,0L16.26,4.45h0l-3.72-2.3A.85.85,0,0,0,12.25,2h-.08a.82.82,0,0,0-.27,0h-.1a1.13,1.13,0,0,0-.33.13L4,6.78l-.09.07-.09.08L3.72,7l-.05.06,0,0-.06.15,0,.09v.06a.69.69,0,0,0,0,.2v8.73a1,1,0,0,0,.47.85l7.5,4.64h0l0,0,.15.06.08,0a.86.86,0,0,0,.52,0l.08,0,.15-.06,0,0h0L20,17.21a1,1,0,0,0,.47-.85V7.63S20.49,7.56,20.49,7.52ZM12,4.17l1.78,1.1L8.19,8.73,6.4,7.63Zm-1,15L5.5,15.81V9.42l5.5,3.4Zm1-8.11L10.09,9.91l5.59-3.47L17.6,7.63Zm6.5,4.72L13,19.2V12.82l5.5-3.4Z"/></svg>
                </div>
                <div class="log-out__item-title h5 ml05 cursor">Đơn hàng</div>
            </router-link>
            <div 
                class="w100 log-out__item f aic cursor"
                @click="handleLogout">
                <div style="width: 24px; height: 24px">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#757575" d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/></svg>
                </div>
                <div class="log-out__item-title h5 ml05 cursor">Đăng xuất</div>
            </div>
        </div>

        <!-- user admin -->
        <div 
            v-if="['ADMIN', 'AFFILIATE', 'CONSULTANT', 'EXPERT'].includes(roleUser)"
            class="w100 f fdc">
            <router-link 
                :to="{ name : 'body-view' , params : {path : 'quan-ly-customer'} }"
                class="w100 log-out__item f aic cursor">
                <div style="width: 24px; height: 24px">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#757575" d="M15.71,12.71a6,6,0,1,0-7.42,0,10,10,0,0,0-6.22,8.18,1,1,0,0,0,2,.22,8,8,0,0,1,15.9,0,1,1,0,0,0,1,.89h.11a1,1,0,0,0,.88-1.1A10,10,0,0,0,15.71,12.71ZM12,12a4,4,0,1,1,4-4A4,4,0,0,1,12,12Z"/></svg>
                </div>
                <div class="log-out__item-title h5 ml05 cursor">Về trang quản trị</div>
            </router-link>
            <div 
                to="/"
                class="w100 log-out__item f aic cursor"
                @click="handleLogout">
                <div style="width: 24px; height: 24px">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#757575" d="M4,12a1,1,0,0,0,1,1h7.59l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H5A1,1,0,0,0,4,12ZM17,2H7A3,3,0,0,0,4,5V8A1,1,0,0,0,6,8V5A1,1,0,0,1,7,4H17a1,1,0,0,1,1,1V19a1,1,0,0,1-1,1H7a1,1,0,0,1-1-1V16a1,1,0,0,0-2,0v3a3,3,0,0,0,3,3H17a3,3,0,0,0,3-3V5A3,3,0,0,0,17,2Z"/></svg>
                </div>
                <div class="log-out__item-title h5 ml05 cursor">Đăng xuất</div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data () {
        return {
            myAccount : {},
            roleUser : ''
        }
    },

    // computed : {
    //     RoleUser () {
    //         return this.checkRoleUser()
    //     }
    // },

    methods : {
        handleLogout() {
            // window.localStorage.clear();
            window.localStorage.removeItem('user');
            window.localStorage.removeItem('myAccount');
            window.localStorage.removeItem('idUser');
            window.localStorage.removeItem('role');

            if (this.$route.path !== '/') this.$router.push({ name: "website", params: { path: "/" } });
            else window.location.reload()
        },

        getDataAccount() {
            var myAccount = localStorage.getItem('myAccount')
            this.myAccount = JSON.parse(myAccount)
        },

        async checkRoleUser () {
            const role = localStorage.getItem('role')
            this.roleUser = role
        },
    },

    mounted () {
        this.getDataAccount()
        this.checkRoleUser()
    }
}
</script>

<style scoped lang='scss'>
    @import 'LogoutView.scss'
</style>
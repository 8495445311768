export const Websocket = require('ws')

export const url_ws = 'wss://api.drmom.store/ws/message/'
export const url_ws_message_conversation = 'wss://api.drmom.store/ws/message-conversation/'

class WebSocketMessage {
  constructor() {
    this.socket = null,
    this.socketConversation = null
  }

  connectConversation(conversation) {
    this.socketConversation = new WebSocket(`${url_ws_message_conversation}?conversation=${conversation}`);
    this.socketConversation.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.socketConversation.onmessage = (message) => {
      console.log('Received message:', message.data);
      // Handle incoming messages
    };

    this.socketConversation.onclose = () => {
      this.socketConversation.close()
      console.log('WebSocket connection closed');
    };


    this.socketConversation.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  connect(token) {
    this.socket = new WebSocket(`${url_ws}?token=${token}`);

    this.socket.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.socket.onmessage = (message) => {
      console.log('Received message:', message.data);
      // Handle incoming messages
    };

    this.socket.onclose = () => {
      console.log('WebSocket connection closed');
    };

    this.socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };
  }

  send(message) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Unable to send message.');
    }
  }
}

export default new WebSocketMessage()